import React from "react"
import { withPrefix } from "gatsby"

import Button from "../Button/Button"

class Alert extends React.Component {
  componentDidMount() {
    let alert = document.createElement("script")
    alert.src = `${withPrefix("scripts/alert-script.js")}`
    alert.id = "alert-script-js"
    document.body.appendChild(alert)
  }

  componentWillUnmount() {
    document.getElementById("alert-script-js").remove()
  }

  render() {
    return (
      <div
        className={`alert alert--${this.props.alertType} alert--is-visible js-alert`}
        role="alert"
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <p>
              <span className="font-primary-bold">
                {this.props.alertTextStrong}
              </span>{" "}
              {this.props.alertTextLight}
            </p>
          </div>

          {this.props.alertIsDismissible && (
            <Button
              btnType="icon"
              btnSize="btn--lg"
              iconName="icon-webapp-close"
              btnClassName="alert__close-btn js-alert__close-btn"
            />
          )}
        </div>
      </div>
    )
  }
}

export default Alert
