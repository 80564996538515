import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Form from "../components/Form/Form"

const Contacto = () => {
  return (
    <Layout>
      <div className="container margin-y-lg">
        <div className="text-component margin-top-lg max-width-xs">
          <h2 className="text-md">Contacto</h2>
          <p>
            Si tiene alguna duda o consulta, rellene el siguiente formulario y
            el departamento correspondiente se pondrá en contacto con usted lo
            antes posible. Gracias.
          </p>
        </div>
        <div className="margin-y-sm max-width-xs">
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
          >
            <Form />
          </GoogleReCaptchaProvider>
        </div>
      </div>
    </Layout>
  )
}

export default Contacto
